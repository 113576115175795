import React, { memo } from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import SharedHeadShotStats from "@/shared-fps/HeadShotStats.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default memo(function HeadShotStats({ steamId }: { steamId: string }) {
  const {
    deadlock: { profileStats },
  } = useSnapshot(readState);
  const _profileStat = profileStats[steamId];
  const matches: Array<HeadShotStatsMatch> = [];
  const lastNStats: LastNStats = {
    headshots: 0,
    bodyshots: 0,
    chestshots: 0,
    stomachshots: 0,
    legshots: 0,
  };
  return (
    <Card padding={0}>
      <SharedHeadShotStats
        hiddenLegshots
        hideHits={false}
        lastNStats={lastNStats}
        matches={matches}
      />
    </Card>
  );
});

type LastNStats = {
  headshots: number;
  bodyshots: number;
  chestshots: number;
  stomachshots: number;
  legshots: number;
};
type HeadShotStatsMatch = {
  index: number;
  hsPercent?: number;
  lastNAvgHS?: number;
  imgUrl?: string;
};
