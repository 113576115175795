import React, { useMemo } from "react";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import ProfileHeroes from "@/game-deadlock/components/ProfileHeroes.jsx";
import ProfileOverview from "@/game-deadlock/components/ProfileOverview.jsx";
import ProfilePerks from "@/game-deadlock/components/ProfilePerks.jsx";
import type { MatchlistMeta } from "@/game-deadlock/models/matchlist.mjs";
import Assets from "@/game-deadlock/utils/Assets.mjs";
import BlitzLogoLoading from "@/shared/BlitzLogoLoading.jsx";
import Container from "@/shared/ContentContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import SharedProfile from "@/shared/Profile.jsx";
import { ProfileLayout } from "@/shared/Profile.style.jsx";
import { useRoute } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TABS = {
  OVERVIEW: "overview",
  HEROES: "heroes",
  PERKS: "perks",
};

export default function Profile() {
  const {
    parameters: [steamId, tab],
  } = useRoute();
  const {
    deadlock: { profiles, steam },
  } = useSnapshot(readState);
  const profile = profiles[steamId] as MatchlistMeta["player"]; // Todo: Remove type narrowing when getPlayer works
  const name = useMemo(() => steam[steamId]?.name ?? steamId, [steam, steamId]);
  const SelectedTab = useMemo(() => {
    switch (tab) {
      case TABS.HEROES:
        return <ProfileHeroes />;
      case TABS.PERKS:
        return <ProfilePerks />;
      default:
        return <ProfileOverview />;
    }
  }, [tab]);
  return (
    <ProfileLayout>
      {profile ? (
        <>
          <PageHeader
            imageRound
            title={name}
            accentText=""
            image={
              Assets.getHeroPortrait(profile.hero_id) ||
              Assets.getDeadlockLogo()
            }
          />
          <Container>
            <SharedProfile>{SelectedTab}</SharedProfile>
          </Container>
        </>
      ) : (
        <Container>
          <div className="relative">
            <Card loading style={{ height: "var(--sp-48)" }} />
            <div className="absolute-center">
              <BlitzLogoLoading />
            </div>
          </div>
        </Container>
      )}
    </ProfileLayout>
  );
}

export function meta([steamId]) {
  const name = readState.deadlock.steam[steamId]?.name ?? "Unknown";
  return {
    title: [
      `deadlock:meta.profile.title`,
      `{{name}}'s - Deadlock Performance Overview`,
      { name },
    ],
    description: [
      `deadlock:meta.profile.description`,
      `View {{name}}'s Deadlock profile and see how they perform.`,
      { name },
    ],
  };
}
