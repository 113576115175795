import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { css } from "goober";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import calcKDA from "@/shared-fps/calc-kda.mjs";
import { displayRate, sanitizeNumber } from "@/util/helpers.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default memo(function ProfileStats({ steamId }: { steamId: string }) {
  const {
    deadlock: { profileStats },
  } = useSnapshot(readState);
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const profileStat = profileStats[steamId];
  const stats = useMemo(() => {
    const gamesPlayed = sanitizeNumber(profileStat?.games_played);
    return [
      {
        right: gamesPlayed,
        left: t("common:matches", "Matches"),
      },
      {
        right: displayRate(sanitizeNumber(profileStat?.wins), gamesPlayed),
        left: t("lol:winRate", "Win Rate"),
      },
      {
        right: calcKDA(
          profileStat?.kills,
          profileStat?.deaths,
          profileStat?.assists,
        ),
        left: t("common:stats.kda", "KDA"),
      },
      {
        right: sanitizeNumber(
          profileStat?.last_hits / gamesPlayed,
        ).toLocaleString(language, { maximumFractionDigits: 1 }),
        left: t("common:stats.avgLastHits", "Avg. Last Hits"),
      },
      {
        right: sanitizeNumber(profileStat?.denies / gamesPlayed).toLocaleString(
          language,
          { maximumFractionDigits: 1 },
        ),
        left: t("common:stats.avgDenies", "Avg. Denies"),
      },
      {
        right: sanitizeNumber(
          profileStat?.net_worth / gamesPlayed,
        ).toLocaleString(language, { maximumFractionDigits: 1 }),
        left: t("common:stats.avgSouls", "Avg. Souls"),
      },
      {
        right: sanitizeNumber(
          profileStat?.shots_missed / profileStat?.shots_hit,
        ).toLocaleString(language, { maximumFractionDigits: 1 }),
        left: t("common:stats.avgSouls", "Accuracy"),
      },
    ];
  }, [language, profileStat, t]);
  return (
    <Card>
      <div className="flex gap-4 column">
        <div className="type-title--bold">
          {t("common:csgo.gameMode.casual", "Casual")}
        </div>
        <div className={cssGrid()}>
          {stats.map((i) => (
            <div
              key={i.left + i.right}
              className="flex align-center justify-between gap-4"
            >
              <div className="shade2" style={{ fontSize: "var(--sp-3)" }}>
                {i.left}
              </div>
              <div
                className="shade0 type-title--bold"
                style={{ fontSize: "var(--sp-3_5)" }}
              >
                {i.right}
              </div>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
});

const cssGrid = () => css`
  display: grid;
  gap: var(--sp-2) var(--sp-4);
  grid-template-columns: repeat(2, 1fr);
`;
